<template>
  <div id="Map"></div>
</template>
<script>
let map, makerList; 
export default {
  data(){
      return{
      }
  },
  mounted(){
  },
  methods:{
      init(siteList, area, lon, lat,){
          let _this = this
          var opts = {
              subdistrict: 2,
              extensions: 'all',
              level: 'province'
          };
          //利用行政区查询获取边界构建mask路径
          //也可以直接通过经纬度构建mask路径
          var district = new AMap.DistrictSearch(opts);
          district.search(area, function(status, result) {
              var bounds = result.districtList[0].boundaries;

              map = new AMap.Map('Map', {
                  center:[lon, lat],
                  viewMode:'3D',
                  labelzIndex:103,
                  pitch:-35,
                  zooms:[7,22],
                  zoom:9,
                  mapStyle: 'amap://styles/blue',
              });
              var object3Dlayer = new AMap.Object3DLayer({zIndex:1});
              map.add(object3Dlayer)
              var height = 200000;
              var color = '#0088ff55';//rgba
              var wall = new AMap.Object3D.Prism({
                  path:bounds,
                  height:height,
                  color:color
              });
              wall.transparent = true
              wall.backOrFront = 'both';
              object3Dlayer.add(wall)
              //添加描边
              for(var i =0;i<bounds.length;i+=1){
                  new AMap.Polyline({
                      path:bounds[i],
                      strokeColor:'#99ffff',
                      strokeWeight:4,
                      map:map
                  })
              }
              makerList = []
              for(let i = 0; i < siteList.length; i++){
                  let data = siteList[i]
                  let maker = new AMap.Marker({
                      position:new AMap.LngLat(data.lnglat[0], data.lnglat[1]),
                      content: `<div class="pointSite"><div class="PSlabel">${data.name}</div></div>`,
                      extData:data,
                  })
                  makerList.push(maker)
                  maker.on('click', (e)=>{
                      _this.setView(data)
                      // makerList.forEach(e=>{
                      //     let name = e.getExtData().name
                      //     e.setzIndex(101)
                      //     e.setContent(`<div class="pointSite"><div class="PSlabel">${name}</div></div>`)
                      // })
                      // maker.setzIndex(102)
                      // maker.setContent(`<div class="pointSite"><div class="PSlabel PSactive">${data.name}</div></div>`)

                  });
              }
              new AMap.MarkerClusterer(map, makerList, {gridSize: 60});
              // map.addControl(
              //     new AMap.ToolBar()
              // );
          });
      },
      setView(data){
        const { href } = this.$router.resolve({
              name: "siteHome",
              query: {
                  id: data.value,
                  name:data.name
              }
          });
          window.open(href, '_blank');
      }
  }
}
</script>
<style lang="scss" scoped>
#Map{ 
  width: 100%;
  height: calc(100vh - 60px);
  background: rgba(0,0,0,0) !important;
}
/deep/.amap-logo{
  display: none !important;
}
/deep/.pointSite{
  width:16px;
  height: 16px;
  background: #FFD369;
  border-radius: 50%;
  position: relative;
  .PSlabel{
      position: absolute;
      top:-42px;
      left:-87px;
      min-width: 200px;
      line-height: 30px;
      font-size: 20px;
      color: white;
      background: #0E1F4E;
      border-radius: 20px;
      border: 1px solid #FFD369;
      text-align: center;
  }
  .PSactive{
      background: #FFD369;
      color: #0E1F4E;
  }
  .PSlabel::before{
      position: absolute;
      content: '';
      height: 0;
      width: 0;
      right: calc(50% - 5px);
      top: 1.5em;
      border-width: 10px;
      border-style: solid;
      border-color: #FFD369 transparent transparent transparent;
  }
}
</style>